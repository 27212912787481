import React from 'react'

import Layout from '../components/layout'
import ComplainsOne from '../components/strategy/ComplainsOne'


const Work = props => (
  <Layout lang="pt" title="Política de Gestão de Reclamações" meta={{description: ''}} rootProps={{ className: 'page strategy' }}>
    <ComplainsOne lang="pt" />
  </Layout>
)

export default Work
